import { Component, OnInit } from '@angular/core';
import { IonContent } from '@ionic/angular/standalone';
import {
  faUserGraduate,
  faUsers,
  faUserTieHair,
} from '@fortawesome/pro-duotone-svg-icons';
import { RESOURCE_PERMISSION } from '@shared/role-bace-acces-controller/resource-permission.constant';
import {
  IPageItem,
  PagesListComponent,
} from '../../shared/components/pages-list/pages-list.component';
import { IMenuRoutes } from '@layout/menu-routes.interface';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.page.html',
  standalone: true,
  imports: [IonContent, PagesListComponent],
})
export class UserManagementPage implements OnInit {
  listings = userManagementPageRoutes();

  ngOnInit(): void {}
}

export function userManagementPageRoutes(): Array<IMenuRoutes> {
  return [
    {
      faIcon: { icon: faUserGraduate },
      titleI18nKey: 'global.students.btn',
      path: '/user-management/students',
      permissions: [RESOURCE_PERMISSION.student.viewStudentsList],
    },
    {
      faIcon: { icon: faUserTieHair },
      titleI18nKey: 'global.guardians.btn',
      path: '/user-management/guardians',
      permissions: [RESOURCE_PERMISSION.guardians.viewGuardiansList],
    },
    {
      faIcon: { icon: faUsers },
      titleI18nKey: 'global.personnels.btn',
      path: '/user-management/personnels',
      permissions: [RESOURCE_PERMISSION.personnel.viewPersonnelList],
    },
  ];
}
